import {Line} from 'model/model'
import {useTransform} from 'editor/EditorView'
import {observer} from 'mobx-react-lite'
import {useProjectStore} from 'model/ProjectProvider'
import {useCallback} from 'react'

interface LineMarkerProps {
    line: Line
}

const LineMarker = ({line}: LineMarkerProps) => {
    const {toScreen} = useTransform()
    const p = toScreen(line.from.pos)
    const q = toScreen(line.to.pos)
    const project = useProjectStore()

    const onMouseDown = useCallback((ev) => {
        ev.preventDefault()
        if (project.tool === 'edit') {
            project.select(line)
        }
    }, [project, line])

    return (
        <>
            <line x1={p.x} y1={p.y} x2={q.x} y2={q.y} className='line-marker-hitbox'
                  onMouseDown={onMouseDown}/>
            <line x1={p.x} y1={p.y} x2={q.x} y2={q.y} className={getClass(line)}/>
        </>
    )
}

export default observer(LineMarker)

const getClass = (line: Line) => {
    let rv = 'line-marker'
    rv += ` line-marker-${line.connected ? 'online' : 'offline'}`
    rv += ` line-marker-${line.level}`
    rv += ` line-marker-${line.voltage}`
    return rv
}
