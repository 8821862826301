import {useProjectStore} from 'model/ProjectProvider'
import {Button} from 'react-bootstrap'
import {useCallback, useRef} from 'react'


export const ImportNodesButton = () => {
    const p = useProjectStore()
    const inputEl = useRef<HTMLInputElement>(null)

    const onClick = useCallback(() => {
        inputEl.current?.click()
    }, [])

    const onSelectFile = useCallback(async () => {
        const file = inputEl.current?.files?.[0]
        if (!file) { return }
        await p.importNodes(file)
    }, [p])

    return (
        <Button variant='primary' onClick={onClick}>
            Import...
            <input ref={inputEl} type='file' accept='.csv' className='d-none' onChange={onSelectFile}/>
        </Button>
    )
}


export const ExportNodesButton = () => {
    const p = useProjectStore()
    const onClick = useCallback(() => {
        p.exportNodes()
    }, [p])

    return (
        <Button variant='primary' onClick={onClick}>Export...</Button>
    )
}


export const ExportBillOfMaterialsButton = () => {
    const p = useProjectStore()
    const onClick = useCallback(() => {
        p.exportBillOfMaterials()
    }, [p])

    return (
        <Button variant='primary' onClick={onClick}>Export...</Button>
    )
}
