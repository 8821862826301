import {observer} from 'mobx-react-lite'
import {useProjectStore} from 'model/ProjectProvider'
import {Point} from 'model/model'
import {createContext, MouseEvent, useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react'
import NodeMarker from 'editor/NodeMarker'
import './editor.scss'
import {Box} from 'editor/MapView'
import LineToolView from 'editor/LineToolView'
import LineMarker from 'editor/LineMarker'
import JointMarker from 'editor/JointMarker'
import {autorun} from 'mobx'
import GenerationToolView from 'editor/GenerationToolView'
import GenerationMarker from 'editor/GenerationMarker'
import {useGoogleMap} from '@react-google-maps/api'
import {Coord} from 'model/math'


interface EditorViewProps {
    projection: Box<google.maps.MapCanvasProjection>
}

const EditorView = ({projection}: EditorViewProps) => {
    const project = useProjectStore()
    const map = useGoogleMap()

    useEffect(() => {
        return autorun(() => {
            console.log(`nodes  : ${project.model.nodes.length}\nlines  : ${project.model.lines.length}\njoints : ${project.model.joints.length}`)
        })
    }, [project.model])


    const pr = projection.value

    // handle point transformations: map <-> screen

    const ref = useRef<SVGSVGElement | null>(null)

    // NOTE: depends on `projection` and not `pr`
    const toMap = useCallback(({x, y}: Point): Coord | undefined => {
        if (!ref.current) { return }
        const m = ref.current.getScreenCTM()
        if (!m) { return }
        const viewToScreen = new DOMMatrix([m.a, m.b, m.c, m.d, m.e, m.f]) // repack into DOMMatrix
        const screenToView = viewToScreen.inverse()
        const screenPoint = new DOMPoint(x, y)
        const viewPoint = screenToView.transformPoint(screenPoint)
        const mapPoint = pr.fromContainerPixelToLatLng(new google.maps.Point(viewPoint.x, viewPoint.y))
        return {lat: mapPoint.lat(), lng: mapPoint.lng()}
    }, [projection])

    // NOTE: depends on `projection` and not `pr`
    const toScreen = useCallback(({lat, lng}: Coord): Point => {
        const p = pr.fromLatLngToContainerPixel(new google.maps.LatLng(lat, lng))
        return {x: p.x, y: p.y}
    }, [projection])

    const transformValue: TransformValue = useMemo(() => ({toMap, toScreen}), [toMap, toScreen])


    // Drag & Drop

    const {start, move, end} = useDragCanvas()
    const onDragStart = useCallback((s: DragState) => {
        map?.setOptions({gestureHandling: 'none'})
        start(s)
    }, [map, start])
    const onDragEnd = useCallback((ev: MouseEvent) => {
        map?.setOptions({gestureHandling: 'auto'})
        end(ev)
    }, [map, end])
    const dragValue: DragValue = useMemo(() => ({start: onDragStart}), [onDragStart])


    // Mouse

    const onMouseMove = (ev: MouseEvent) => {
        const p = toMap({x: ev.clientX, y: ev.clientY})
        if (!p) { return }
        if (project.tool === 'line') {
            project.lineTool.move(p)
        } else if (project.tool === 'generation') {
            project.generationTool.move(p)
        } else {
            move(ev)
        }
    }
    const onMouseUp = (ev: MouseEvent) => {
        const p = toMap({x: ev.clientX, y: ev.clientY})
        if (!p) { return }
        if (project.tool === 'node') {
            project.model.addNode(p)
        } else if (project.tool === 'line') {
            project.lineTool.click(p)
        } else if (project.tool === 'generation') {
            project.generationTool.click(p)
        } else {
            onDragEnd(ev)
            project.model.recalculate()
        }
    }


    return (
        <DragContext.Provider value={dragValue}>
            <TransformContext.Provider value={transformValue}>
                <svg ref={ref} width='100%' height='100%'
                     onMouseMove={onMouseMove} onMouseUp={onMouseUp} onMouseLeave={onDragEnd}>
                    <SvgDefs/>

                    {project.model.lines.map(x =>
                        <LineMarker key={x.id} line={x}/>)}
                    {project.model.nodes.map(x =>
                        <NodeMarker key={x.id} node={x}/>)}
                    {project.model.joints.map(x =>
                        <JointMarker key={x.id} joint={x}/>)}
                    {project.model.generation &&
                    <GenerationMarker generation={project.model.generation}/>}

                    {project.tool === 'line' && <LineToolView/>}
                    {project.tool === 'generation' && <GenerationToolView/>}
                </svg>
            </TransformContext.Provider>
        </DragContext.Provider>
    )
}

export default observer(EditorView)


const SvgDefs = () => {
    return (
        <defs>
            <filter id='highlight' filterUnits='userSpaceOnUse'>
                <feGaussianBlur in='SourceAlpha' stdDeviation={5}/>
                <feOffset dx={0} dy={0} result='blur'/>
                <feFlood floodColor='#0000ff'/>
                <feComposite in2='blur' operator='in'/>
                <feComponentTransfer>
                    <feFuncA type='linear' slope={1}/>
                </feComponentTransfer>
                <feMerge>
                    <feMergeNode/>
                    <feMergeNode in='SourceGraphic'/>
                </feMerge>
            </filter>
        </defs>
    )
}


// Transform Context

interface TransformValue {
    toMap: (p: Point) => Coord | undefined
    toScreen: (p: Coord) => Point
}

const TransformContext = createContext({} as TransformValue)

export const useTransform = () => useContext(TransformContext)


// Drag Context

interface DragValue {
    start: (s: DragState) => void
}

const DragContext = createContext({} as DragValue)

export const useDrag = () => useContext(DragContext)

interface DragState {
    onMove?: (ev: MouseEvent) => void
    onStart?: () => void
    onEnd?: () => void
}

const useDragCanvas = () => {
    const [state, setState] = useState<DragState | null>(null)

    const start = useCallback((s: DragState) => {
        setState(s)
        s.onStart?.()
    }, [])

    const end = useCallback((ev: MouseEvent) => {
        if (!state) { return }
        state.onEnd?.()
        setState(null)
    }, [state])

    const move = useCallback((ev: MouseEvent) => {
        if (!state) { return }
        state.onMove?.(ev)
    }, [state])

    return {start, move, end}
}
