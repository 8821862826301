import {Joint} from 'model/model'
import {useDrag, useTransform} from 'editor/EditorView'
import {observer} from 'mobx-react-lite'
import {MouseEvent, useCallback} from 'react'
import {useProjectStore} from 'model/ProjectProvider'

interface EndMarkerProps {
    joint: Joint
}

const JointMarker = ({joint}: EndMarkerProps) => {
    const {toScreen, toMap} = useTransform()
    const {start} = useDrag()
    const project = useProjectStore()

    const onMouseDown = useCallback((ev: MouseEvent) => {
        ev.preventDefault()
        if (project.tool === 'edit') {
            start({onMove: onDrag})
        }
    }, [start])

    // logic on drag&drop
    const onDrag = useCallback((ev: MouseEvent) => {
        const p = {x: ev.clientX, y: ev.clientY}
        const pos = toMap(p)
        if (!pos) { return }
        joint.setPos(pos)
    }, [joint, toMap])

    const p = toScreen(joint.pos)
    return (
        <circle cx={p.x} cy={p.y} r={3} className='joint-marker'
                onMouseDown={onMouseDown}/>
    )
}

export default observer(JointMarker)
