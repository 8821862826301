import {action, makeObservable, observable} from 'mobx'
import {ProjectStore} from 'model/ProjectStore'
import {Coord, zeroCoord} from 'model/math'


type State = 'new' | 'dragging'

export class LineTool {
    @observable state: State = 'new'
    @observable from: Coord = zeroCoord()
    @observable to: Coord = zeroCoord()

    constructor(readonly project: ProjectStore) {
        makeObservable(this)
    }

    @action click(p: Coord) {
        const q = this.project.adjust(p)
        switch (this.state) {
            case 'new':
                this.from = q
                this.to = q
                this.state = 'dragging'
                break
            case 'dragging':
                this.to = q
                this.project.model.addLine(this.from, this.to)
                this.state = 'new'
                break
        }
    }

    @action move(p: Coord) {
        const q = this.project.adjust(p)
        switch (this.state) {
            case 'new':
                this.from = q
                break
            case 'dragging':
                this.to = q
                break
        }
    }

    @action cancel() {
        this.state = 'new'
    }

    @action start() {
    }
}
