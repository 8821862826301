import {observer} from 'mobx-react-lite'
import {GoogleMap} from '@react-google-maps/api'
import {useCallback, useState} from 'react'
import EditorView from 'editor/EditorView'
import CustomOverlayView from 'editor/CustomOverlayView'
import {useProjectStore} from 'model/ProjectProvider'


export interface Box<T> {value: T}

const MapView = () => {
    const [map, setMap] = useState<google.maps.Map>()
    const [overlay, setOverlay] = useState<google.maps.OverlayView>()
    const [bounds, setBounds] = useState<google.maps.LatLngBounds>()
    const [projection, setProjection] = useState<Box<google.maps.MapCanvasProjection>>()

    const project = useProjectStore()

    const onLoadMap = useCallback((instance: google.maps.Map) => {
        setMap(instance)

        instance.setOptions({
            draggableCursor: 'crosshair',
            clickableIcons: false,
            mapTypeId: 'roadmap',
            mapTypeControl: true,
            mapTypeControlOptions: {
                mapTypeIds: ['roadmap', 'hybrid'],
                style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
            },
            scaleControl: true,
        })
    }, [])
    const onLoadOverlay = useCallback((instance: google.maps.OverlayView) => {
        setOverlay(instance)
    }, [])

    const onBoundsChanged = useCallback(() => {
        // sync map and overlay bounds
        const bounds = map?.getBounds()
        if (!bounds) { return }
        setBounds(bounds)
    }, [map])

    const onDraw = useCallback(() => {
        // extract projection value
        const projection = overlay?.getProjection()
        setProjection({value: projection!})
    }, [overlay])

    const onCenterChanged = useCallback(() => {
        const center = map?.getCenter()
        if (!center) { return }
        project.setMapCenter({lat: center.lat(), lng: center.lng()})
    }, [project, map])

    const onZoomChanged = useCallback(() => {
        const zoom = map?.getZoom()
        if (!zoom) { return }
        project.setMapZoom(zoom)
    }, [project, map])


    return (
        <GoogleMap
            mapContainerClassName='map-container'
            center={project.mapCenter} onCenterChanged={onCenterChanged}
            zoom={project.mapZoom} onZoomChanged={onZoomChanged}
            onBoundsChanged={onBoundsChanged}
            onLoad={onLoadMap}>
            <CustomOverlayView mapPaneName='overlayMouseTarget'
                               bounds={bounds}
                               onLoad={onLoadOverlay}
                               onDraw={onDraw}>
                <div className='border border-danger w-100 h-100'>
                    {projection?.value && <EditorView projection={projection}/>}
                </div>
            </CustomOverlayView>
        </GoogleMap>
    )
}


export default observer(MapView)
