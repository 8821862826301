import {useProjectStore} from 'model/ProjectProvider'
import {observer} from 'mobx-react-lite'
import {useTransform} from 'editor/EditorView'
import {Coord} from 'model/math'


const GenerationToolView = () => {
    const {generationTool: t} = useProjectStore()
    switch (t.state) {
        case 'new':
            return (<Marker pos={t.pos}/>)
        case 'done':
            return (<NoOpMarker pos={t.pos}/>)
    }
}

export default observer(GenerationToolView)


// end marker

interface MarkerProps {
    pos: Coord
}

const Marker = ({pos}: MarkerProps) => {
    const {toScreen} = useTransform()
    const p = toScreen(pos)
    return (
        <circle cx={p.x} cy={p.y} r={8} className='generation-tool-marker'/>
    )
}


// no-op marker

interface NoOpMarkerProps {
    pos: Coord
}

const NoOpMarker = ({pos}: NoOpMarkerProps) => {
    const {toScreen} = useTransform()
    const p = toScreen(pos)
    return (
        <circle cx={p.x} cy={p.y} r={8} className='generation-tool-noop'/>
    )
}
