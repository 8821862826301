import {makeObservable, observable} from 'mobx'
import {Level} from 'model/model'


export class Settings {
    constructor() {makeObservable(this)}

    @observable maxPoleToPoleDistance: number = 50.0 // m
    @observable maxPoleToConnectionDistance: number = 10.0 // m

    @observable lowVoltage: number = 220.0 // V
    @observable mediumVoltage: number = 33000.0 // V
    @observable generationVoltage: number = 230.0 // V
    @observable generationFrequency: number = 50.0 // Hz
    @observable conductorSpacing: number = 0.5 // m

    @observable minimumAllowableVoltage: number = 220.0 // V
    @observable maximumAllowablePowerLoss: number = 20.0 // W%

    @observable poleCost: number = 250.0 // $
    @observable transformerCost: number = 5000.0 // $
    @observable meterCost: number = 25.0 // $
    @observable protectionCost: number = 20.0 // $

    @observable defaultConductor: number = 0 // index
    @observable defaultDemand: number = 3000.0 // W
    @observable defaultPowerFactor: number = 1.0
    @observable defaultCustomerLevelOfService: Level = 'phase1'
    @observable defaultLineLevelOfService: Level = 'phase1'
}
