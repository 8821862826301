import {action, computed, makeObservable, observable} from 'mobx'
import {v4} from 'uuid'
import {almostEq, Coord, distance, zeroCoord} from 'model/math'
import {Settings} from 'model/Settings'
import {calculate} from 'model/calculation'
import {BoM, buildBillOfMaterials} from 'model/bom'


export interface Point {
    x: number
    y: number
}


export class Model {
    @observable name: string = 'model'
    @observable generation: Generation | null = null
    nodes: Node[] = observable([])
    joints: Joint[] = observable([])
    lines: Line[] = observable([])

    @observable settings: Settings = new Settings()

    @observable conductors: Conductor[] = observable(defaultConductors)


    constructor() {
        makeObservable(this)
        //this.nodes.splice(0, 0, ...createRandomNodes({lat: 40.0150, lng: -105.2705}))
    }


    @action addNode(pos: Coord, update: boolean = true): Node {
        const node = new Node()
        node.pos = pos
        node.demand = this.settings.defaultDemand
        node.factor = this.settings.defaultPowerFactor
        node.level = this.settings.defaultCustomerLevelOfService
        this.nodes.push(node)
        if (update) {this.recalculate()}
        return node
    }

    @action removeNode(id: string) {
        const i = this.nodes.findIndex(x => x.id === id)
        if (i === -1) { return }
        this.nodes.splice(i, 1)
        this.recalculate()
    }


    @action addLine(from: Coord, to: Coord, update: boolean = true): Line {
        const fromJoint = this.findOrCreateJoint(from)
        const toJoint = this.findOrCreateJoint(to)
        const conductor = this.conductors[0] // pick first
        const line = new Line(fromJoint, toJoint, conductor)
        fromJoint.addAdjLine(line)
        toJoint.addAdjLine(line)
        line.conductor = this.conductors[this.settings.defaultConductor]
        line.level = this.settings.defaultLineLevelOfService
        this.lines.push(line)
        if (update) {this.recalculate()}
        return line
    }

    @action removeLine(id: string) {
        const i = this.lines.findIndex(x => x.id === id)
        if (i === -1) { return }
        const line = this.lines[i]
        this.lines.splice(i, 1)
        line.from.removeAdjLine(line)
        line.to.removeAdjLine(line)
        this.removeJoint(line.from)
        this.removeJoint(line.to)
        this.recalculate()
    }


    findOrCreateJoint(p: Coord): Joint {
        let joint = this.findJoint(p)
        if (!joint) {
            joint = new Joint(p)
            this.joints.push(joint)
        }
        return joint
    }

    findJoint(p: Coord): Joint | null {
        return this.joints.find(x => almostEq(p, x.pos)) ?? null
    }

    removeJoint(joint: Joint) {
        if (joint.adj.length > 0) { return }
        const i = this.joints.indexOf(joint)
        this.joints.splice(i, 1)
    }


    @action addGeneration(p: Coord, update: boolean = true): Generation {
        const joint = this.findOrCreateJoint(p)
        const generation = new Generation(joint)
        this.generation = generation
        if (update) {this.recalculate()}
        return generation
    }

    @action removeGeneration() {
        if (!this.generation) { return }
        this.removeJoint(this.generation.joint)
        this.generation = null
        this.recalculate()
    }

    @computed get totalNodes(): number { return this.nodes.length }


    // calculations

    @action recalculate() {
        console.log('recalculate')

        calculate(this)
        const bom = buildBillOfMaterials(this, this.settings)
        this.bom = bom
    }

    @observable bom: BoM = []
}


export type Level = 'phase1' | 'phase3wye' | 'phase3delta'

export const labelLevel = (x: Level) => {
    switch (x) {
        case 'phase1':
            return '1-Phase'
        case 'phase3wye':
            return '3-Phase (wye)'
        case 'phase3delta':
            return '3-Phase (delta)'
    }
}

export type Voltage = 'low' | 'medium'

export const labelVoltage = (x: Voltage) => {
    switch (x) {
        case 'low':
            return 'Low'
        case 'medium':
            return 'Medium'
    }
}


export interface Item {
    readonly id: string
}


export class Generation {
    id = v4()
    readonly type = 'generation'
    @observable capacity: number = 10000.0
    @observable level: Level = 'phase1'

    constructor(readonly joint: Joint) {
        makeObservable(this)
    }

    @action setCapacity(x: number) { this.capacity = x }
    @action setLevel(x: Level) { this.level = x }
}


export class Node {
    id = v4()
    readonly type = 'node'
    @observable pos: Coord = zeroCoord()
    @observable name: string = 'Customer'
    @observable demand: number = 3000.0
    @observable factor: number = 1.0
    @observable level: Level = 'phase1'

    constructor() { makeObservable(this) }

    @action setPos(pos: Coord) { this.pos = pos }
    @action setName(x: string) { this.name = x }
    @action setDemand(x: number) { this.demand = x }
    @action setFactor(x: number) { this.factor = x }
    @action setLevel(x: Level) { this.level = x }

    @observable connection: Connection | null = null
    @computed get connected(): boolean { return this.connection !== null }

    // TODO: metrics: voltage drop (nominal, percent); power loss (nimonal, percent)
}


export class Line {
    id = v4()
    readonly type = 'line'
    @observable conductor: Conductor
    @observable level: Level = 'phase1'
    @observable voltage: Voltage = 'low'

    constructor(public from: Joint, public to: Joint, conductor: Conductor) {
        this.conductor = conductor
        makeObservable(this)
    }

    @computed get length() { return distance(this.from.pos, this.to.pos) }

    @action setConductor(x: Conductor) {this.conductor = x }
    @action setLevel(x: Level) { this.level = x }
    @action setVoltage(x: Voltage) { this.voltage = x }

    @computed get connected() { return this.from.connected || this.to.connected }

    other(joint: Joint): Joint { return this.from === joint ? this.to : this.from }

    // reorder joints: from -> to
    order(from: Joint) {
        if (this.from === from) { return }
        const t = this.from
        this.from = this.to
        this.to = t
    }

    // TODO: metrics: voltage drop; power loss
}


export class Joint {
    id = v4()
    readonly type = 'node'
    @observable pos: Coord

    adj: Line[] = []
    @observable connected: boolean = false
    uplink: Joint | null = null
    uplinkLine: Line | null = null
    distance: number | null = null // distance to generation

    constructor(pos: Coord) {
        this.pos = pos
        makeObservable(this)
    }

    @action setPos(pos: Coord) { this.pos = pos }

    addAdjLine(line: Line) {
        this.adj.push(line)
    }

    removeAdjLine(line: Line) {
        const i = this.adj.indexOf(line)
        if (i === -1) { return }
        this.adj.splice(i, 1)
    }

    * downlinks() {
        for (const line of this.adj) {
            const v = line.other(this)
            if (v === this.uplink) { continue }
            yield v
        }
    }

    * downlinksLine() {
        for (const line of this.adj) {
            if (line === this.uplinkLine) { continue }
            yield line
        }
    }

    get needTransformer(): boolean {
        const hasLow = this.adj.some(x => x.voltage === 'low')
        const hasMedium = this.adj.some(x => x.voltage === 'medium')
        return hasLow && hasMedium
    }
}


export class Conductor {
    id = v4()
    name: string
    area: number = 1 // mm²
    k: number = 1
    @observable price: number = 1 // $/m

    // ohm/km
    resistance() { return 18.4 * this.k / this.area }

    // ohm/km
    reactance(f: number = 50 /* frequency (Hz) */, s: number = 0.5 /* conductor spacing (m) */) {
        const d = Math.sqrt(4 * this.area / Math.PI) * 1e-3
        return 2 * Math.PI * f * (19 + 46 * Math.log10(s / d)) * 1e-5
    }

    constructor(name?: string, area?: number, k?: number, price?: number) {
        this.name = name ?? 'conductor'
        this.area = area ?? 1
        this.k = k ?? 1
        this.price = price ?? 1
        makeObservable(this)
    }
}


export const defaultConductors = [
    new Conductor('Aluminum 20mm²', 20, 1.6, 0.75),
    new Conductor('Aluminum 30mm²', 30, 1.6, 1.00),
    new Conductor('Aluminum 45mm²', 45, 1.6, 1.50),
    new Conductor('Aluminum 75mm²', 75, 1.6, 2.25),
    new Conductor('Aluminum 120mm²', 120, 1.6, 3.00),
    new Conductor('Aluminum 145mm²', 145, 1.6, 3.75),
    new Conductor('Aluminum 185mm²', 185, 1.6, 4.75),
    new Conductor('Aluminum 230mm²', 230, 1.6, 5.75),
    new Conductor('Copper 20mm²', 20, 1.0, 2.50),
    new Conductor('Copper 30mm²', 30, 1.0, 3.50),
    new Conductor('Copper 45mm²', 45, 1.0, 5.00),
    new Conductor('Copper 75mm²', 75, 1.0, 7.50),
    new Conductor('Copper 120mm²', 120, 1.0, 9.00),
    new Conductor('Copper 145mm²', 145, 1.0, 12.50),
    new Conductor('Copper 185mm²', 185, 1.0, 15.80),
    new Conductor('Copper 230mm²', 230, 1.0, 17.25),
]


export class Connection {
    constructor(public line: Line, public pos: Coord) {}
}


const createRandomNodes = (center: Coord, k: number = 1000): Node[] => {
    const nn = []
    for (let i = 0; i < k; i++) {
        const n = new Node()
        n.pos = {lat: center.lat + (Math.random() - 0.5) / 100, lng: center.lng + (Math.random() - 0.5) / 100}
        nn.push(n)
    }
    return nn
}
