export interface AppParams {
    authToken: string
    env: string
    envUi: string
    projectId: string
    distributionDesignId: string
    latitude: number
    longitude: number
}


export const getParams = (): AppParams => {
    const url = window.location.href
    const i = url.indexOf('#')
    if (i === -1) { return {...defaultAppParams} }
    const params = new URLSearchParams(url.substr(i + 1))
    const result: AppParams = {
        authToken: params.get('authToken') ?? defaultAppParams.authToken,
        env: params.get('env') ?? defaultAppParams.env,
        envUi: params.get('envUi') ?? defaultAppParams.envUi,
        projectId: params.get('projectId') ?? defaultAppParams.projectId,
        distributionDesignId: params.get('distributionDesignId') ?? defaultAppParams.distributionDesignId,
        latitude: Number(params.get('latitude') ?? defaultAppParams.latitude),
        longitude: Number(params.get('longitude') ?? defaultAppParams.longitude),
    }
    if (!params.get('envUi')) { result.envUi = result.env.replace('dt.', 'ui.') }
    return result
}


export const defaultAppParams: AppParams = {
    authToken: '6a4ec2bb-8ec0-4853-9d17-b606e4cfe724',
    env: 'https://qa-dt.odyssey.energy/',
    envUi: 'https://qa-ui.odyssey.energy/',
    projectId: '_',
    distributionDesignId: '_',
    latitude: 40.0150,
    longitude: -105.2705,
}
