import {action, makeObservable, observable} from 'mobx'
import {ProjectStore} from 'model/ProjectStore'
import {Coord, zeroCoord} from 'model/math'


type State = 'new' | 'done'

export class GenerationTool {
    @observable state: State = 'new'
    @observable pos: Coord = zeroCoord()

    constructor(readonly project: ProjectStore) {
        makeObservable(this)
        this.start()
    }

    @action click(p: Coord) {
        const q = this.project.adjust(p)
        switch (this.state) {
            case 'new':
                this.pos = q
                this.project.model.addGeneration(this.pos)
                this.state = 'done'
                break
            case 'done':
                break
        }
    }

    @action move(p: Coord) {
        const q = this.project.adjust(p)
        this.pos = q
    }

    @action cancel() {
    }

    @action start() {
        this.state = this.project.model.generation !== null ? 'done' : 'new'
    }
}
