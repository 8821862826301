import {observer} from 'mobx-react-lite'
import {Tab, Tabs} from 'react-bootstrap'
import {useCallback, useEffect, useState} from 'react'
import {AgGridColumn, AgGridReact} from 'ag-grid-react'
import {GridApi, GridReadyEvent, ValueFormatterParams, ValueParserParams} from 'ag-grid-community'
import {useProjectStore} from 'model/ProjectProvider'
import {Conductor, defaultConductors, Item, labelLevel, labelVoltage, Level, Node, Voltage} from 'model/model'
import {IObservableArray, observe} from 'mobx'
import {Coord} from 'model/math'
import {ExportBillOfMaterialsButton, ExportNodesButton, ImportNodesButton} from 'grid/ExportImportViews'

const GridsView = () => {
    return (
        <Tabs>
            <Tab eventKey='nodes' title={<span>Customers</span>}>
                <NodesTab/>
            </Tab>
            <Tab eventKey='lines' title={<span>Lines</span>}>
                <LinesTab/>
            </Tab>
            <Tab eventKey='bom' title={<span>BOM</span>}>
                <BillOfMaterialsTab/>
            </Tab>
        </Tabs>
    )
}

export default observer(GridsView)


const NodesTab = observer(() => {
    const project = useProjectStore()

    const [gridApi, setGridApi] = useState<GridApi>()

    const onGridReady = useCallback((ev: GridReadyEvent) => {
        setGridApi(ev.api)
    }, [])

    useEffect(() => {
        if (!gridApi) { return }
        return trackArray(project.model.nodes as IObservableArray, gridApi)
    }, [project.model, gridApi])

    return (
        <>
            <div className='d-inline-flex m-2'>
                <ExportNodesButton/>
                <ImportNodesButton/>
            </div>
            <div className='ag-theme-bootstrap grid-container'>
                <AgGridReact rowData={project.model.nodes}
                             onGridReady={onGridReady} getRowNodeId={itemId}>
                    <AgGridColumn field='pos' headerName='Geotag' width={150}
                                  valueFormatter={posFormatter}/>
                    <AgGridColumn field='name' headerName='Customer Type' width={250}
                                  editable={true}/>
                    <AgGridColumn field='demand' headerName='Peak Power (W)' width={140} type='numericColumn'
                                  editable={true} valueFormatter={num0Formatter} valueParser={numParser}/>
                    <AgGridColumn field='factor' headerName='Power Factor' width={140} type='numericColumn'
                                  editable={true} valueFormatter={num2Formatter} valueParser={numParser}/>
                    <AgGridColumn field='level' headerName='Level of Service' width={150}
                                  editable={true} valueFormatter={levelFormatter}
                                  cellEditor='agSelectCellEditor' cellEditorParams={levelEditorParams}/>
                </AgGridReact>
            </div>
        </>
    )
})


const LinesTab = observer(() => {
    const project = useProjectStore()

    const [gridApi, setGridApi] = useState<GridApi>()

    const onGridReady = useCallback((ev: GridReadyEvent) => {
        setGridApi(ev.api)
    }, [])

    useEffect(() => {
        if (!gridApi) { return }
        return trackArray(project.model.lines as IObservableArray, gridApi)
    }, [project.model, gridApi])

    return (
        <div className='ag-theme-bootstrap grid-container'>
            <AgGridReact rowData={project.model.lines}
                         onGridReady={onGridReady} getRowNodeId={itemId}>
                <AgGridColumn field='conductor' headerName='Conductor' width={250}
                              editable={true} valueFormatter={conductorFormatter}
                              cellEditor='agSelectCellEditor' cellEditorParams={conductorEditorParams}/>
                <AgGridColumn field='length' headerName='Length (m)' width={150}
                              valueFormatter={num0Formatter}/>
                <AgGridColumn field='level' headerName='Level of Service' width={150}
                              editable={true} valueFormatter={levelFormatter}
                              cellEditor='agSelectCellEditor' cellEditorParams={levelEditorParams}/>
                <AgGridColumn field='voltage' headerName='Voltage' width={150}
                              editable={true} valueFormatter={voltageFormatter}
                              cellEditor='agSelectCellEditor' cellEditorParams={voltageEditorParams}/>
            </AgGridReact>
        </div>
    )
})


const BillOfMaterialsTab = observer(() => {
    const project = useProjectStore()

    return (
        <>
            <div className='d-inline-flex m-2'>
                <ExportBillOfMaterialsButton/>
            </div>
            <div className='ag-theme-bootstrap grid-container'>
                <AgGridReact rowData={project.model.bom}>
                    <AgGridColumn field='type' headerName='Type' width={200}/>
                    <AgGridColumn field='count' headerName='Quantity' width={100} type='numericColumn'
                                  valueFormatter={num0Formatter}/>
                    <AgGridColumn field='unitCost' headerName='Unit Cost' width={100} type='numericColumn'
                                  valueFormatter={num2Formatter}/>
                    <AgGridColumn field='units' headerName='Unit' width={100}
                                  valueFormatter={unitFormatter}/>
                    <AgGridColumn field='totalCost' headerName='Total Cost' width={100} type='numericColumn'
                                  valueFormatter={num2Formatter}/>
                </AgGridReact>
            </div>
        </>
    )
})


// formatters, parsers, options

// TODO: move to grid utils
export const itemId = (x: Item) => x.id

const levelFormatter = (params: ValueFormatterParams) => {
    const x = params.value as Level
    return labelLevel(x)
}

const voltageFormatter = (params: ValueFormatterParams) => {
    const x = params.value as Voltage
    return labelVoltage(x)
}

const num0Formatter = (params: ValueFormatterParams) => {
    const x = params.value as number
    return x.toFixed(0)
}

export const num2Formatter = (params: ValueFormatterParams) => {
    const x = params.value as number
    return x.toFixed(2)
}

const posFormatter = (params: ValueFormatterParams) => {
    const x = params.value as Coord
    return `(${x.lat.toFixed(3)}, ${x.lng.toFixed(3)})`
}

const unitFormatter = (params: ValueFormatterParams) => {
    const x = params.value as string
    return x.toUpperCase()
}

export const conductorFormatter = (params: ValueFormatterParams) => {
    const x = params.value as Conductor
    return x.name
}

const levelValues: Level[] = ['phase1', 'phase3wye', 'phase3delta']
const levelEditorParams = {values: levelValues}

const voltageValues: Voltage[] = ['low', 'medium']
const voltageEditorParams = {values: voltageValues}

const conductorValues: Conductor[] = defaultConductors
const conductorEditorParams = {values: conductorValues}

export const numParser = (params: ValueParserParams) => {
    return Number(params.newValue)
}


// TODO: move to grid utils
// tracking

type Disposer = () => void

export const trackArray = (items: IObservableArray, gridApi: GridApi): Disposer => {
    const disposers = new Map<string, any>()

    const trackItem = (item: Item) => {
        const d = observe(item,
            (x) => {
                const row = gridApi.getRowNode(x.object.id)
                row?.setData(x.object)
            })
        disposers.set(item.id, d)
    }

    const untrackItem = (item: Item) => {
        const d = disposers.get(item.id)
        if (d) { d() }
        disposers.delete(item.id)
    }

    const disposer = observe(items, change => {
        switch (change.type) {
            case 'splice':
                gridApi.applyTransaction({
                    add: change.added.map(x => x),
                    addIndex: change.index,
                    remove: change.removed.map(x => x),
                })
                for (const item of change.added as Item[]) {trackItem(item)}
                for (const item of change.removed as Node[]) {untrackItem(item)}
                break
        }
    })
    return () => {
        disposer()
        for (const [_, value] of disposers) { value() }
    }
}
