import {Level, Model, Voltage} from 'model/model'
import {Settings} from 'model/Settings'


type UnitsType = 'unit' | 'meter'

export class BillItem {
    type: string = ''
    description: string = ''
    count: number = 1
    unitCost: number = 1
    units: UnitsType = 'unit'

    get totalCost(): number { return this.count * this.unitCost }

    constructor(x?: Partial<BillItem>) { Object.assign(this, x) }
}

export type BoM = BillItem[]


export const buildBillOfMaterials = ({nodes, lines, joints}: Model, settings: Settings): BoM => {
    const result: BoM = []

    // cabling
    for (const line of lines) {
        const item = new BillItem({
            type: cableLabel(line.voltage),
            description: line.conductor.name,
            count: line.length * cableMultiplier(line.level),
            unitCost: line.conductor.price,
            units: 'meter',
        })
        result.push(item)
    }

    // TODO: update to explicit poles
    // poles
    let totalPoles = 0
    // count poles: for a line except ends
    for (const line of lines) {
        const hops = Math.floor(line.length / settings.maxPoleToPoleDistance)
        totalPoles += hops
    }
    // count poles: lines ends only (1 pole per connection of multiple lines)
    totalPoles += joints.length
    const polesItem = new BillItem({
        type: 'Poles',
        count: totalPoles,
        unitCost: settings.poleCost,
    })
    result.push(polesItem)

    // transformers / 1 per joint, if there are low & medium lines there
    const totalTransformers = joints.filter(x => x.needTransformer).length
    const transformersItem = new BillItem({
        type: 'Transformers',
        count: totalTransformers,
        unitCost: settings.transformerCost,
    })
    result.push(transformersItem)

    // meters / only for connected customers
    const totalMeters = nodes.filter(x => x.connected).length
    const metersItem = new BillItem({
        type: 'Metering',
        count: totalMeters,
        unitCost: settings.meterCost,
    })
    result.push(metersItem)

    // wiring & protections / only for connected customers
    const totalProtections = nodes.filter(x => x.connected).length
    const protectionsItem = new BillItem({
        type: 'Grid Connection',
        count: totalProtections,
        unitCost: settings.protectionCost,
    })
    result.push(protectionsItem)

    return bomSort(bomAggregateByTypeAndDescription(bomRemoveEmpty(result)))
}

const bomRemoveEmpty = (bom: BoM): BoM => {
    return bom.filter(x => x.count > 0)
}

const bomAggregateByTypeAndDescription = (bom: BoM): BoM => {
    const m = new Map<string, BillItem>()
    for (const item of bom) {
        const key = `${item.type}_${item.description}`
        if (m.has(key)) {
            const x = m.get(key)!
            x.count += item.count
        } else {
            m.set(key, item)
        }
    }
    const rv = []
    for (const item of m.values()) {
        rv.push(item)
    }
    return rv
}

const bomSort = (bom: BoM): BoM => {
    return bom.sort((a, b) => a.type.localeCompare(b.type))
}


const cableLabel = (x: Voltage): string => {
    switch (x) {
        case 'low':
            return 'Low Voltage Cabling'
        case 'medium':
            return 'Medium Voltage Cabling'
    }
}

const cableMultiplier = (x: Level): number => {
    switch (x) {
        case 'phase1':
            return 2
        case 'phase3wye':
            return 4
        case 'phase3delta':
            return 3
    }
}


export const createBillOfMaterialsCsv = (bom: BoM): string => {
    let r = 'Type,Details,Quantity,Cost,Unit'
    for (const item of bom) {
        const row = `${item.type},${item.description},${item.count},${item.unitCost},${item.units}`
        r += '\n' + row
    }
    return r
}
