import {observer} from 'mobx-react-lite'
import {useCallback, useEffect, useState} from 'react'
import {Button, Modal, Tab, Tabs} from 'react-bootstrap'
import {ConductorSelector, LevelSelector, NumberField} from 'property/PropertiesView'
import {useProjectStore} from 'model/ProjectProvider'
import {GridApi, GridReadyEvent} from 'ag-grid-community'
import {IObservableArray} from 'mobx'
import {AgGridColumn, AgGridReact} from 'ag-grid-react'
import {itemId, num2Formatter, numParser, trackArray} from 'grid/GridsView'


const SettingsView = () => {
    const [show, setShow] = useState(false)
    const onShow = () => { setShow(true) }
    const onClose = () => { setShow(false) }

    // TODO: recalculate after settings update

    return (
        <>
            <Button variant='primary' onClick={onShow}>Settings</Button>
            <Modal show={show} onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Settings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tabs>
                        <Tab eventKey='parameters' title={<span>Parameters</span>}>
                            <ConfigurationsView/>
                        </Tab>
                        <Tab eventKey='costs' title={<span>Default Costs</span>}>
                            <DefaultCostsView/>
                        </Tab>
                        <Tab eventKey='settings' title={<span>Default Settings</span>}>
                            <DefaultSettingsView/>
                        </Tab>
                        <Tab eventKey='library' title={<span>Library</span>}>
                            <LibraryView/>
                        </Tab>
                    </Tabs>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='primary' onClick={onClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default observer(SettingsView)


interface SettingFieldProp {
    name: string
    units?: string
    value: number
    onChange: (x: number) => void
}

const SettingField = ({name, units, value, onChange}: SettingFieldProp) => {
    const label = !units ? `${name}:` : `${name} (${units}):`
    return (
        <div className='form-group col-6'>
            <label>{label}</label>
            <NumberField value={value} onChange={onChange}/>
        </div>
    )
}


const ConfigurationsView = observer(() => {
    const {settings} = useProjectStore().model

    // TODO: use setters
    return (
        <div className='form-row'>
            <SettingField name='Max pole-to-pole distance' units='m' value={settings.maxPoleToPoleDistance} onChange={x => settings.maxPoleToPoleDistance = x}/>
            <SettingField name='Max pole-to-connection distance' units='m' value={settings.maxPoleToConnectionDistance} onChange={x => settings.maxPoleToConnectionDistance = x}/>

            <SettingField name='Low Voltage' units='V' value={settings.lowVoltage} onChange={x => settings.lowVoltage = x}/>
            <SettingField name='Medium Voltage' units='V' value={settings.mediumVoltage} onChange={x => settings.mediumVoltage = x}/>
            <SettingField name='Generation Voltage' units='V' value={settings.generationVoltage} onChange={x => settings.generationVoltage = x}/>
            <SettingField name='Generation Frequency' units='Hz' value={settings.generationFrequency} onChange={x => settings.generationFrequency = x}/>
            <SettingField name='Conductor Spacing' units='m' value={settings.conductorSpacing} onChange={x => settings.conductorSpacing = x}/>
            <div className='col-6'/>

            <SettingField name='Minimum Allowable Voltage' units='V' value={settings.minimumAllowableVoltage} onChange={x => settings.minimumAllowableVoltage = x}/>
            <SettingField name='Maximum Allowable Power Loss' units='%' value={settings.maximumAllowablePowerLoss} onChange={x => settings.maximumAllowablePowerLoss = x}/>
        </div>
    )
})


const DefaultCostsView = observer(() => {
    const {settings} = useProjectStore().model

    // TODO: use setters
    return (
        <div className='form-row'>
            <SettingField name='Pole Cost' units='$' value={settings.poleCost} onChange={x => settings.poleCost = x}/>
            <SettingField name='Transformer Cost' units='$' value={settings.transformerCost} onChange={x => settings.transformerCost = x}/>
            <SettingField name='Meter Cost' units='$' value={settings.meterCost} onChange={x => settings.meterCost = x}/>
            <SettingField name='Protection Cost' units='$' value={settings.protectionCost} onChange={x => settings.protectionCost = x}/>
        </div>
    )
})


const DefaultSettingsView = observer(() => {
    const {settings, conductors} = useProjectStore().model

    return (
        <div className='form-row'>
            <div className='form-group col-6'>
                <label>Default Line Conductor:</label>
                <ConductorSelector value={conductors[settings.defaultConductor]} onChange={x => settings.defaultConductor = conductors.indexOf(x)}/>
            </div>
            <div className='col-6'/>

            <SettingField name='Default Peak Power' units='W' value={settings.defaultDemand} onChange={x => settings.defaultDemand = x}/>
            <div className='form-group col-6'>
                <label>Default Line Level of Service:</label>
                <LevelSelector value={settings.defaultLineLevelOfService} onChange={x => settings.defaultLineLevelOfService = x}/>
            </div>
            <SettingField name='Default Power Factor' value={settings.defaultPowerFactor} onChange={x => settings.defaultPowerFactor = x}/>
            <div className='form-group col-6'>
                <label>Default Customer Level of Service:</label>
                <LevelSelector value={settings.defaultCustomerLevelOfService} onChange={x => settings.defaultCustomerLevelOfService = x}/>
            </div>
        </div>
    )
})


const LibraryView = observer(() => {
    const project = useProjectStore()

    const [gridApi, setGridApi] = useState<GridApi>()

    const onGridReady = useCallback((ev: GridReadyEvent) => { setGridApi(ev.api) }, [])

    useEffect(() => {
        if (!gridApi) { return }
        gridApi.sizeColumnsToFit()
        return trackArray(project.model.conductors as IObservableArray, gridApi)
    }, [project.model, gridApi])

    return (
        <div className='ag-theme-bootstrap grid-container'>
            <AgGridReact rowData={project.model.conductors}
                         onGridReady={onGridReady} getRowNodeId={itemId}>
                <AgGridColumn field='name' headerName='Conductor' width={360}/>
                <AgGridColumn field='price' headerName='Price ($)' width={100} type='numericColumn'
                              editable={true} valueFormatter={num2Formatter} valueParser={numParser}/>
            </AgGridReact>
        </div>
    )
})
