import {Coord, distance} from 'model/math'

// precision: 4  digits
const precisionK = 10000

// TODO: check negative numbers?
const bin = (a: number): number => Math.floor(Math.abs(a) * precisionK) % precisionK

interface Cell {
    x: number
    y: number
}

const cellId = (c: Cell) => c.x * precisionK + c.y

function* candidateCells(c: Cell): Generator<Cell> {
    yield {x: c.x, y: c.y}

    yield {x: c.x - 1, y: c.y}
    yield {x: c.x + 1, y: c.y}
    yield {x: c.x, y: c.y - 1}
    yield {x: c.x, y: c.y + 1}

    yield {x: c.x - 1, y: c.y - 1}
    yield {x: c.x + 1, y: c.y - 1}
    yield {x: c.x - 1, y: c.y + 1}
    yield {x: c.x + 1, y: c.y + 1}
}

const toCell = (u: Coord) => ({x: bin(u.lng), y: bin(u.lat)})

function findNearest<T>(u: Coord, kvs: [Coord, T][] | null, range: number): T | null {
    if (!kvs) { return null }
    for (const kv of kvs) {
        if (distance(u, kv[0]) < range) { return kv[1] }
    }
    return null
}


export class SpatialHash<T> {
    constructor() {}

    hash: Map<number, [Coord, T][]> = new Map<number, [Coord, T][]>()

    add(u: Coord, value: T) {
        const id = cellId(toCell(u))
        const kv: [Coord, T] = [u, value]
        const xs = this.hash.get(id)
        if (!xs) {
            this.hash.set(id, [kv])
        } else {
            xs.push(kv)
        }
    }

    findNearest(u: Coord, range: number): T | null {
        const cv = toCell(u)
        for (const c of candidateCells(cv)) {
            const id = cellId(c)
            const vs = this.hash.get(id) ?? null
            const r = findNearest(u, vs, range)
            if (r) { return r }
        }
        return null
    }
}
