import {Generation} from 'model/model'
import {useDrag, useTransform} from 'editor/EditorView'
import {MouseEvent, useCallback} from 'react'
import {observer} from 'mobx-react-lite'
import {useProjectStore} from 'model/ProjectProvider'

interface GenerationMarkerProps {
    generation: Generation
}

const GenerationMarker = ({generation}: GenerationMarkerProps) => {
    const {toScreen, toMap} = useTransform()
    const {start} = useDrag()
    const project = useProjectStore()

    const onMouseDown = useCallback((ev: MouseEvent) => {
        ev.preventDefault()
        if (project.tool === 'edit') {
            project.select(generation)
            start({onMove: onDrag})
        }
    }, [project, generation, start])

    // logic on drag&drop
    const onDrag = useCallback((ev: MouseEvent) => {
        const p = {x: ev.clientX, y: ev.clientY}
        const pos = toMap(p)
        if (!pos) { return }
        generation.joint.setPos(pos)
    }, [generation, toMap])

    const p = toScreen(generation.joint.pos)
    return (
        <circle cx={p.x} cy={p.y} r={8} className='generation-marker'
                onMouseDown={onMouseDown}/>
    )
}

export default observer(GenerationMarker)
