import {Coord} from 'model/math'
import {Level, Model, Node, Voltage} from 'model/model'
import {ProjectStore} from 'model/ProjectStore'
import {parse} from 'papaparse'
import {Settings} from 'model/Settings'


export interface ModelJson {
    name?: string
    generation?: GenerationJson
    nodes?: NodeJson[]
    segments?: LineJson[]
    settings?: SettingsJson
    location?: LocationJson
    bom?: BillItemJson[]
}

export interface GenerationJson extends Coord {
    capacity: number
    service: number //enum
}

export interface NodeJson extends Coord {
    name: string
    demand: number
    powerFactor: number
    service: number //enum
}

export interface LineJson {
    from: Coord
    to: Coord
    conductor: string
    voltage: number //enum
    service: number //enum
}

export interface SettingsJson {
    maxPoleToPoleDistance?: number
    maxPoleToConnectionDistance?: number
    lowVoltage?: number
    mediumVoltage?: number
    generationVoltage?: number
    generationFrequency?: number
    conductorSpacing?: number
    minimumAllowableVoltage?: number
    maximumAllowablePowerLoss?: number
    poleCost?: number
    transformerCost?: number
    meterCost?: number
    protectionCost?: number
    defaultConductor?: number
    defaultCustomerLevelOfService?: number
    defaultLineLevelOfService?: number
    defaultDemand?: number
    defaultPowerFactor?: number
}

export interface LocationJson {
    typeid: string
    center: Coord
    zoom: number
}

export interface BillItemJson {
    type: string
    description: string
    count: number
    unitCost: number
    units: string
}


/*
export enum ServiceType {
  Phase1 = 0
  Phase3Wye = 1
  Phase3Delta = 2
}
*/

const getLevelEnum = (x: Level): number => {
    switch (x) {
        case 'phase1':
            return 0
        case 'phase3wye':
            return 1
        case 'phase3delta':
            return 2
    }
}

const parseLevelEnum = (x: number): Level => {
    switch (x) {
        case 0:
            return 'phase1'
        case 1:
            return 'phase3wye'
        case 2:
            return 'phase3delta'
        default:
            console.log('ERROR: invalid Level')
            return 'phase1' // default
    }
}


/*
export enum VoltageType {
  Low = 0
  Medium = 1
}
*/

const getVoltageEnum = (x: Voltage): number => {
    switch (x) {
        case 'low':
            return 0
        case 'medium':
            return 1
    }
}

const parseVoltageEnum = (x: number): Voltage => {
    switch (x) {
        case 0:
            return 'low'
        case 1:
            return 'medium'
        default:
            console.log('ERROR: invalid Voltage')
            return 'low' // defaults
    }
}


export const toJson = (project: ProjectStore, model: Model): ModelJson => {
    model.recalculate()
    const {generation: g, nodes, lines, settings: s} = model

    return {
        name: '',

        generation: g ? {
            lat: g.joint.pos.lat,
            lng: g.joint.pos.lng,
            capacity: g.capacity,
            service: getLevelEnum(g.level),
        } : undefined,

        nodes: nodes.map(x => ({
            name: x.name,
            lat: x.pos.lat,
            lng: x.pos.lng,
            demand: x.demand,
            powerFactor: x.factor,
            service: getLevelEnum(x.level),
        })),

        segments: lines.map(x => ({
            from: {...x.from.pos},
            to: {...x.to.pos},
            conductor: x.conductor.name,
            voltage: getVoltageEnum(x.voltage),
            service: getLevelEnum(x.level),
        })),

        settings: {
            maxPoleToPoleDistance: s.maxPoleToPoleDistance,
            maxPoleToConnectionDistance: s.maxPoleToConnectionDistance,
            lowVoltage: s.lowVoltage,
            mediumVoltage: s.mediumVoltage,
            generationVoltage: s.generationVoltage,
            generationFrequency: s.generationFrequency,
            conductorSpacing: s.conductorSpacing,
            minimumAllowableVoltage: s.minimumAllowableVoltage,
            maximumAllowablePowerLoss: s.maximumAllowablePowerLoss,
            poleCost: s.poleCost,
            transformerCost: s.transformerCost,
            meterCost: s.meterCost,
            protectionCost: s.protectionCost,
            defaultConductor: s.defaultConductor,
            defaultDemand: s.defaultDemand,
            defaultPowerFactor: s.defaultPowerFactor,
            defaultCustomerLevelOfService: getLevelEnum(s.defaultCustomerLevelOfService),
            defaultLineLevelOfService: getLevelEnum(s.defaultLineLevelOfService),
        },

        location: {
            // TODO: update type id from map
            typeid: 'roadmap',
            center: project.mapCenter,
            zoom: project.mapZoom,
        },

        bom: model.bom.map(x => ({
            type: x.type,
            description: x.description,
            count: x.count,
            unitCost: x.unitCost,
            units: x.units,
        })),
    }
}


export const fromJson = (json: ModelJson): Model => {
    const m = new Model()

    m.name = json.name ?? 'model'

    // TODO: process location

    if (json.settings) {
        const s = json.settings
        const ms = m.settings
        const def = new Settings()
        ms.maxPoleToPoleDistance = s.maxPoleToPoleDistance ?? def.maxPoleToPoleDistance
        ms.maxPoleToConnectionDistance = s.maxPoleToConnectionDistance ?? def.maxPoleToConnectionDistance
        ms.lowVoltage = s.lowVoltage ?? def.lowVoltage
        ms.mediumVoltage = s.mediumVoltage ?? def.mediumVoltage
        ms.generationVoltage = s.generationVoltage ?? def.generationVoltage
        ms.generationFrequency = s.generationFrequency ?? def.generationFrequency
        ms.conductorSpacing = s.conductorSpacing ?? def.conductorSpacing
        ms.minimumAllowableVoltage = s.minimumAllowableVoltage ?? def.minimumAllowableVoltage
        ms.maximumAllowablePowerLoss = s.maximumAllowablePowerLoss ?? def.maximumAllowablePowerLoss
        ms.poleCost = s.poleCost ?? def.poleCost
        ms.transformerCost = s.transformerCost ?? def.transformerCost
        ms.meterCost = s.meterCost ?? def.meterCost
        ms.protectionCost = s.protectionCost ?? def.protectionCost
        ms.defaultConductor = s.defaultConductor ?? def.defaultConductor
        ms.defaultDemand = s.defaultDemand ?? def.defaultDemand
        ms.defaultPowerFactor = s.defaultPowerFactor ?? def.defaultPowerFactor
        ms.defaultCustomerLevelOfService = parseLevelEnum(s.defaultCustomerLevelOfService ?? 0)
        ms.defaultLineLevelOfService = parseLevelEnum(s.defaultLineLevelOfService ?? 0)
    }

    if (json.generation) {
        const g = json.generation
        const mg = m.addGeneration(g, false)
        mg.capacity = g.capacity
        mg.level = parseLevelEnum(g.service)
    }

    if (json.nodes) {
        const ns = json.nodes
        for (const n of ns) {
            const mn = m.addNode(n, false)
            mn.name = n.name
            mn.demand = n.demand
            mn.factor = n.powerFactor
            mn.level = parseLevelEnum(n.service)
        }
    }

    if (json.segments) {
        const ls = json.segments
        for (const l of ls) {
            const ml = m.addLine(l.from, l.to, false)
            ml.conductor = m.conductors.find(x => x.name === l.conductor) ?? m.conductors[0]
            ml.voltage = parseVoltageEnum(l.voltage)
            ml.level = parseLevelEnum(l.service)
        }
    }

    m.recalculate()

    return m
}


// export CSV

export const createNodesCsv = (nodes: Node[]) => {
    let r = 'latitude,longitude,name,service,peak,powerFactor'
    for (const n of nodes) {
        const row = `${n.pos.lat.toFixed(5)},${n.pos.lng.toFixed(5)},${n.name},${getLevelEnum(n.level)},${n.demand.toFixed(3)},${n.factor.toFixed(2)}`
        r += '\n' + row
    }
    return r
}


// import CSV

const readFile = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onerror = reject
        reader.onload = () => {
            const result = reader.result as string
            resolve(result)
        }
        reader.readAsText(file)
    })
}

interface NodeCsv {
    latitude?: string
    longitude?: string
    name?: string
    service?: string
    peak?: string
    powerFactor?: string
}

export const readNodesCsv = async (file: File): Promise<NodeCsv[]> => {
    const t = await readFile(file)
    const result = await parse(t, {skipEmptyLines: true, header: true})
    const data = result.data as NodeCsv[]
    return data
}

export const addNodes = (model: Model, nodes: NodeCsv[]) => {
    for (const n of nodes) {
        const mn = model.addNode({lat: Number(n.latitude) ?? 0, lng: Number(n.longitude) ?? 0}, false)
        mn.name = n.name ?? 'Customer'
        mn.demand = Number(n.peak) ?? 3000.0
        mn.factor = Number(n.powerFactor) ?? 1.0
        mn.level = parseLevelEnum(Number(n.service) ?? 0)
    }
    model.recalculate()
}
