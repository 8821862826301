import {action, computed, makeObservable, observable, runInAction} from 'mobx'
import {Generation, Line, Model, Node} from 'model/model'
import {LineTool} from 'model/LineTool'
import {almostEq, Coord, distance} from 'model/math'
import {GenerationTool} from 'model/GenerationTool'
import {AppParams, defaultAppParams} from 'model/AppParams'
import {addNodes, createNodesCsv, fromJson, readNodesCsv, toJson} from 'model/serialization'
import {createBillOfMaterialsCsv} from 'model/bom'
import {saveAs} from 'file-saver'


export class ProjectStore {
    constructor() {
        makeObservable(this)
        this.lineTool = new LineTool(this)
        this.generationTool = new GenerationTool(this)
    }

    @observable params: AppParams = {...defaultAppParams}
    @observable model: Model = new Model()


    // params
    @action
    async setParams(params: AppParams) {
        this.params = params
        this.setMapCenter({lat: params.latitude, lng: params.longitude})
        await this.loadModel()
    }

    // URLs
    @computed get modelLoadUrl() { return `${this.params.env}api/1.0.0/distribution-design-tool/projects/${this.params.projectId}/distribution-designs/${this.params.distributionDesignId}/${this.params.authToken}` }
    @computed get modelSaveUrl() { return `${this.params.env}api/1.0.0/distribution-design-tool/projects/${this.params.projectId}/distribution-designs/${this.params.distributionDesignId}/results/${this.params.authToken}` }
    @computed get mainAppUrl() { return `${this.params.envUi}#/oes/projects/my-projects/${this.params.projectId}/distribution-design` }


    // tools
    readonly generationTool: GenerationTool
    readonly lineTool: LineTool

    @observable tool: Tool = 'edit'

    @action selectTool(x: Tool) {
        this.tool = x
        // TODO: `start` only selected tool
        this.lineTool.start()
        this.generationTool.start()
    }

    // selection

    @observable selection: Selection = null
    @action select(x: Selection) { this.selection = x }


    @action removeSelection() {
        if (!this.selection) { return }
        switch (this.selection.type) {
            case 'generation':
                this.model.removeGeneration()
                break
            case 'node':
                this.model.removeNode(this.selection.id)
                break
            case 'line':
                this.model.removeLine(this.selection.id)
                break

        }
        // TODO: recalculate
    }


    // map

    @observable mapZoom: number = defaultMapZoom
    @observable mapCenter: Coord = defaultMapCenter

    @action setMapZoom(x: number) { this.mapZoom = x }
    @action setMapCenter(x: Coord) {
        if (almostEq(this.mapCenter, x)) { return }
        this.mapCenter = x
    }


    // snap to points

    * enumerateSnapPoints(): IterableIterator<Coord> {
        for (const joint of this.model.joints) {
            yield joint.pos
        }
    }

    get snapDistance(): number { return baseSnapDistance / (2 ** (this.mapZoom - baseZoom)) }

    adjust(p: Coord): Coord {
        let minDistance = this.snapDistance
        let min: Coord | null = null
        for (const snap of this.enumerateSnapPoints()) {
            const d = distance(p, snap)
            if (d < minDistance) {
                minDistance = d
                min = snap
            }
        }
        return min ?? p
    }


    // save/load

    @observable apiError: { url: string, error: string, status: number, statusText: string } | null = null

    @action
    async loadModel() {
        this.clearApiError()
        try {
            const r = await fetch(this.modelLoadUrl, {
                method: 'GET',
                headers: {'Content-Type': 'application/json'},
            })
            if (!r.ok) {
                const body = await r.text()
                runInAction(() => {
                    console.error(`failed to load model\n'${this.params.projectId} | ${this.params.distributionDesignId}'\n${body}`)
                    this.apiError = {status: r.status, statusText: r.statusText, url: r.url, error: body}
                })
            } else {
                const data = (await r.json()) as LoadResponse
                const json = JSON.parse(data.distributionDesignToolOutput ?? '{}')
                const model = fromJson(json)
                runInAction(() => {
                    console.log('load success', json)
                    this.model = model
                    if (json.location) {
                        const l = json.location
                        // TODO: set map type
                        this.mapZoom = l.zoom
                        this.mapCenter = l.center
                    }
                })
            }
        } catch (e) {
            console.error(`failed to load model\n'${this.params.projectId} | ${this.params.distributionDesignId}'\n${e.message}`, e)
            runInAction(() => {
                this.apiError = {status: e.status, statusText: e.statusText, url: e.url, error: JSON.stringify(e.error)}
            })
        }
    }

    @action
    async saveModel() {
        this.clearApiError()

        const json = toJson(this, this.model)
        try {
            const r = await fetch(this.modelSaveUrl, {
                method: 'PUT',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(json),
            })
            if (!r.ok) {
                const body = await r.text()
                runInAction(() => {
                    console.error(`failed to save model\n'${this.params.projectId} | ${this.params.distributionDesignId}'\n${body}`)
                    this.apiError = {status: r.status, statusText: r.statusText, url: r.url, error: body}
                })
            }
            runInAction(() => {
                console.log('save success')
                this.saved = true
            })
        } catch (e) {
            console.error(`failed to save model\n'${this.params.projectId} | ${this.params.distributionDesignId}'\n${e.message}`, e)
            runInAction(() => {
                this.apiError = {status: e.status, statusText: e.statusText, url: e.url, error: JSON.stringify(e.error)}
            })
        }
    }

    @action clearApiError() {
        this.apiError = null
    }

    @observable saved: boolean = false

    @action confirmSave() {
        this.saved = false
    }


    @action
    async importNodes(file: File) {
        const data = await readNodesCsv(file)
        runInAction(() => {
            addNodes(this.model, data)
        })
    }

    @action exportNodes() {
        const csv = createNodesCsv(this.model.nodes)
        const file = new Blob([csv], {type: 'text/csv;charset=utf-8'})
        saveAs(file, 'customers.csv')
    }

    @action exportBillOfMaterials() {
        this.model.recalculate()
        const csv = createBillOfMaterialsCsv(this.model.bom)
        const file = new Blob([csv], {type: 'text/csv;charset=utf-8'})
        saveAs(file, 'bill-of-materials.csv')
    }
}

const baseSnapDistance = 40
const baseZoom = 15

const defaultMapZoom = 18
const defaultMapCenter = {lat: 40.0150, lng: -105.2705}


export type Selection = Generation | Node | Line | null

export type Tool = 'edit' | 'generation' | 'node' | 'line'


interface LoadResponse {
    distributionDesignToolOutput?: string
}
