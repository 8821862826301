import {useProjectStore} from 'model/ProjectProvider'
import {observer} from 'mobx-react-lite'
import {useTransform} from 'editor/EditorView'
import {Coord} from 'model/math'


const LineToolView = () => {
    const {lineTool: t} = useProjectStore()

    switch (t.state) {
        case 'new':
            return (
                <EndMarker pos={t.from}/>
            )
        case 'dragging':
            return (
                <>
                    <LineMarker from={t.from} to={t.to}/>
                    <EndMarker pos={t.from}/>
                    <EndMarker pos={t.to}/>
                </>
            )
    }
}

export default observer(LineToolView)


// end marker

interface EndMarkerProps {
    pos: Coord
}

const EndMarker = ({pos}: EndMarkerProps) => {
    const {toScreen} = useTransform()
    const p = toScreen(pos)
    return (
        <circle cx={p.x} cy={p.y} r={3} className='line-tool-end-marker'/>
    )
}


// line marker

interface LineMarkerProps {
    from: Coord
    to: Coord
}

const LineMarker = ({from, to}: LineMarkerProps) => {
    const {toScreen} = useTransform()
    const p = toScreen(from)
    const q = toScreen(to)
    return (
        <line x1={p.x} y1={p.y} x2={q.x} y2={q.y} className='line-tool-line-marker'/>
    )
}
