export interface Coord {
    lat: number
    lng: number
}

export const zeroCoord = () => ({lat:0, lng:0})


const eps = 0.000001 // ~0.1m

export const almostEq = (a: Coord, b: Coord) => {
    return Math.abs(a.lat - b.lat) + Math.abs(a.lng - b.lng) < eps
}


// degrees -> radians
export const deg2rad = (x: number) => {
    return x * (Math.PI / 180.0)
}

export const deg2radCoord = ({lat, lng}: Coord) => {
    return {lat: deg2rad(lat), lng: deg2rad(lng)}
}


// the distance between two lat/lon points (degrees)
export const distance = (a: Coord, b: Coord) => {
    const from = new google.maps.LatLng(a.lat, a.lng)
    const to = new google.maps.LatLng(b.lat, b.lng)
    return google.maps.geometry.spherical.computeDistanceBetween(from, to)
}


// Earth radios (m)
const R = 6371000


// the distance between two lat/lon points
// (radians)
export const _distance = (ar: Coord, br: Coord) => {
    return Math.acos(Math.sin(ar.lat) * Math.sin(br.lat) + Math.cos(ar.lat) * Math.cos(br.lat) * Math.cos(br.lng - ar.lng)) * R
}

// the bearing from one lat/lon point to another
// (radians)
export const _bearing = (ar: Coord, br: Coord) => {
    return Math.atan2(
        Math.sin(br.lng - ar.lng) * Math.cos(br.lat),
        Math.cos(ar.lat) * Math.sin(br.lat) - Math.sin(ar.lat) * Math.cos(br.lat) * Math.cos(br.lng - ar.lng))
}


// the shortest distance in meters between an arc (defined by A and B) and a third point, C
// (degrees)
export const crossArcDistance = (ad: Coord, bd: Coord, cd: Coord) => {
    const ar = deg2radCoord(ad)
    const br = deg2radCoord(bd)
    const cr = deg2radCoord(cd)

    const bearAB = _bearing(ar, br)
    const bearAC = _bearing(ar, cr)
    const distAC = _distance(ar, cr)

    // is relative bearing obtuse?
    if (Math.abs(bearAC - bearAB) > (Math.PI / 2)) {
        return distAC
    } else {
        // find the cross-track distance
        const dxt = Math.asin(Math.sin(distAC / R) * Math.sin(bearAC - bearAB)) * R

        // is p4 beyond the arc?
        const disAB = _distance(ar, br)
        const disA4 = Math.acos(Math.cos(distAC / R) / Math.cos(dxt / R)) * R
        if (disA4 > disAB) {
            return _distance(br, cr)
        } else {
            return Math.abs(dxt)
        }
    }
}
