import {Connection, Generation, Joint, Line, Model, Node} from 'model/model'
import {SpatialHash} from 'model/SpatialHash'


class CalculationResult {
    invalidGeneration: boolean = false
    constructor(x: Partial<CalculationResult>) { Object.assign(this, x) }
}


// == geo metrics ==

// update joints connectivity from generation
// - visit all reachable joints from generation
// - orient lines correctly `from -> to`
// O(#joints)
const updateJointsConnectivity = ({generation, joints}: Model) => {
    // reset
    for (const joint of joints) {
        joint.uplink = null
        joint.connected = false
    }

    if (!generation) { return }

    // bfs
    const queue: Joint[] = []
    queue.push(generation.joint)
    generation.joint.connected = true

    while (queue.length > 0) {
        const v = queue.shift()!
        for (const line of v.adj) {
            const u = line.other(v)
            if (!u.connected) {
                line.order(v) // new line -> fix order `v -> u`
                queue.push(u)
                u.connected = true
                u.uplink = v
                u.uplinkLine = line
            }
        }
    }
}


// update distance from generation
// O(#joints)
const updateDistance = ({generation, joints}: Model) => {
    // reset
    for (const joint of joints) { joint.distance = null }

    if (!generation) { return }

    const queue: Joint[] = []
    queue.push(generation.joint)
    generation.joint.distance = 0.0

    const visited: Set<string> = new Set<string>()
    visited.add(generation.joint.id)

    while (queue.length > 0) {
        const v = queue.shift()!
        if (visited.has(v.id)) { continue }
        for (const line of v.adj) {
            if (line.from !== v) { continue }
            line.to.distance = v.distance! + line.length
            queue.push(line.to)
        }
    }
}


// update connection points of nodes to lines
// O(#nodes * #lines)
const updateNodesConnection = ({nodes, lines}: Model, range: number = 10) => {
    // reset
    for (const node of nodes) { node.connection = null }

    // update
    // 1. build hash of line ends
    const h = new SpatialHash<Line>()
    for (const line of lines.filter(x => x.connected)) {
        h.add(line.to.pos, line)
    }
    for (const node of nodes) {
        const line = h.findNearest(node.pos, range)
        node.connection = line ? new Connection(line, line.to.pos) : null
    }
}


export const calculate = (model: Model) => {
    // geo model
    updateJointsConnectivity(model)
    updateDistance(model)
    updateNodesConnection(model, model.settings.maxPoleToConnectionDistance)

    /*
    // TODO: electric model
    this.buildConnectivityTree();
    this.updateDemand(this.treeRoot);
    this.updateVoltage(this.treeRoot);
    this.updateCurrent(this.treeRoot);
    this.updatePowerFactor(this.treeRoot);
    this.updateLocalVoltageDrop(this.treeRoot);
    this.updateTotalVoltageDrop(this.treeRoot);
    this.updateLocalPowerLoss(this.treeRoot);
    this.updateTotalPowerLoss(this.treeRoot);

    // TODO: checks & metrics
    this.checkServiceType();

    this.updateVoltageDrop();
    this.updatePowerLoss();

    this.countConnectedNodes();
    this.updateSystemVoltageDrop();
    this.updateSystemPowerLoss();

    this.checkDemand();
    this.checkVoltageDrop();
    this.checkPowerLoss();

    this.buildBillOfMaterials();
     */
}


// == electrical metrics ==

type TreeNodeData = Generation | Line | Node

class TreeNode {
    get type(): string { return this.data.type }
    data: TreeNodeData

    parent: TreeNode | null = null
    children: TreeNode[] = []

    // stats
    demand: number = 0 // P
    voltage: number = 0 // E
    current: number = 0 // I
    pfCos: number = 1 // cos(phi) | power factor
    pfSin: number = 0 // sin(phi) | power factor

    localVoltageDrop: number = 0 // VD_line
    voltageDrop: number = 0 // VD
    voltageDropPercent: number = 0 // VD/E*%

    localPowerLoss: number = 0 // Ploss_line
    powerLoss: number = 0 // Ploss
    powerLossPercent: number = 0 // Ploss/P*%

    constructor(data: TreeNodeData) { this.data = data }
}
