import {Node} from 'model/model'
import {MouseEvent, useCallback} from 'react'
import {useDrag, useTransform} from 'editor/EditorView'
import {useProjectStore} from 'model/ProjectProvider'
import {observer} from 'mobx-react-lite'

interface NodeMarkerProps {
    node: Node
}

const NodeMarker = ({node}: NodeMarkerProps) => {
    const {toScreen, toMap} = useTransform()
    const {start} = useDrag()
    const project = useProjectStore()

    const onMouseDown = useCallback((ev: MouseEvent) => {
        ev.preventDefault()
        if (project.tool === 'edit') {
            project.select(node)
            start({onMove: onDrag})
        }
    }, [project, node, start])

    // logic on drag&drop
    const onDrag = useCallback((ev: MouseEvent) => {
        const p = {x: ev.clientX, y: ev.clientY}
        const pos = toMap(p)
        if (!pos) { return }
        node.setPos(pos)
    }, [node, toMap])

    const p = toScreen(node.pos)
    const c = node.connection ? toScreen(node.connection.pos) : null
    return (
        <>
            <circle cx={p.x} cy={p.y} r={6} className={getClass(node)}
                    onMouseDown={onMouseDown}/>
            {c && <line x1={p.x} y1={p.y} x2={c.x} y2={c.y} className='node-marker-connection'/>}
        </>
    )
}

export default observer(NodeMarker)

const getClass = (node: Node) => {
    let rv = 'node-marker'
    rv += ` node-marker-${node.connected ? 'online' : 'offline'}`
    return rv
}
