import React, {useCallback, useEffect, useState} from 'react'
import {ProjectStore} from 'model/ProjectStore'
import ProjectProvider, {useProjectStore} from 'model/ProjectProvider'
import {observer} from 'mobx-react-lite'
import MapView from 'editor/MapView'
import {Button} from 'react-bootstrap'
import PropertiesView from 'property/PropertiesView'
import GridsView from 'grid/GridsView'
import SettingsView from 'settings/SettingsView'
import {getParams} from 'model/AppParams'

function App() {
    const [project] = useState(new ProjectStore())

    useEffect(() => {
        const params = getParams()
        console.log(params)
        project.setParams(params)
    }, [project])

    return (
        <ProjectProvider value={project}>
            <div className='navbar'>
                <div className='navbar-logo-odyssey'/>
            </div>
            <div className='d-flex flex-column align-items-center'>
                <div className='container mx-4'>
                    <div className='py-4 d-flex justify-content-between align-items-center'>
                        <h3 className='m-0'>Distribution Design</h3>
                        <p className='m-0'><a className='text-link--color-brand' href={project.mainAppUrl}>Return to OES Distribution Design</a></p>
                    </div>

                    <div className='d-flex flex-column bg-white p-4 mb-4'>
                        <div className='d-flex flex-row align-items-start'>
                            <div className='d-flex flex-column justify-content-center flex-grow-1'>
                                <div className='mb-4'>
                                    <MapView/>
                                </div>
                                <ToolBar/>
                            </div>
                            <div className='mx-2'/>
                            <div className='w-25'>
                                <PropertiesView/>
                            </div>
                        </div>
                    </div>

                    <div className='mb-4'>
                        <GridsView/>
                    </div>
                </div>
            </div>
        </ProjectProvider>
    )
}


export default observer(App)


// toolbar

const ToolBar = observer(() => {
    const p = useProjectStore()

    const onSave = useCallback(async () => {
        await p.saveModel()
    }, [p])

    return (
        <div className='d-flex justify-content-between flex-wrap'>
            <div className='d-inline-flex'>
                <Button variant='primary' active={p.tool === 'edit'}
                        onClick={() => p.selectTool('edit')}>Edit Mode</Button>
                <Button variant='primary' active={p.tool === 'generation'}
                        onClick={() => p.selectTool('generation')}>Add Generation</Button>
                <Button variant='primary' active={p.tool === 'node'}
                        onClick={() => p.selectTool('node')}>Add Customer</Button>
                <Button variant='primary' active={p.tool === 'line'}
                        onClick={() => p.selectTool('line')}>Add Line</Button>
            </div>
            <div className='d-inline-flex'>
                <Button variant='primary'
                        onClick={onSave}>Save</Button>
                <SettingsView/>
            </div>
        </div>
    )
})
