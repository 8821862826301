import {useProjectStore} from 'model/ProjectProvider'
import {Conductor, defaultConductors, Generation, labelLevel, labelVoltage, Level, Line, Node, Voltage} from 'model/model'
import {observer} from 'mobx-react-lite'
import {Button, Form} from 'react-bootstrap'
import {Selection} from 'model/ProjectStore'


const PropertiesView = () => {
    const project = useProjectStore()

    if (project.selection === null) {
        return (<span>Click on an element to view its properties</span>)
    } else {
        return (
            <div>
                <Header/>
                {project.selection.type === 'generation' &&
                <GenerationPropertiesView generation={project.selection}/>}
                {project.selection.type === 'node' &&
                <NodePropertiesView node={project.selection}/>}
                {project.selection.type === 'line' &&
                <LinePropertiesView line={project.selection}/>}
            </div>
        )
    }
}

export default observer(PropertiesView)


// header

const labelSelection = (x: Selection) => {
    if (!x) { return ''}
    switch (x.type) {
        case 'generation':
            return 'Generation'
        case 'node':
            return 'Customer'
        case 'line':
            return 'Line'
    }
}

export const Header = () => {
    const project = useProjectStore()

    return (
        <div className='d-flex flex-row justify-content-between align-items-center'>
            {labelSelection(project.selection)}
            <Button className='btn-destructive' onClick={() => project.removeSelection()}>Delete</Button>
        </div>
    )
}


// fields

interface FieldProps<T> {
    value: T
    onChange: (x: T) => void
    className?: string
}


export const NumberField = ({value, onChange}: FieldProps<number>) => {
    return (
        <input className='form-control form-control-sm' type='number' placeholder=''
               value={value} onChange={ev => onChange(Number(ev.target.value))}/>
    )
}


const TextField = ({value, onChange}: FieldProps<string>) => {
    return (
        <input className='form-control' type='text' placeholder=''
               value={value} onChange={ev => onChange(ev.target.value)}/>
    )
}


const optionsLevel: Level[] = ['phase1', 'phase3wye', 'phase3delta']

export const LevelSelector = ({value, onChange}: FieldProps<Level>) => {
    return (
        <Form.Control as='select' size='sm' custom value={value} onChange={ev => onChange(ev.target.value as Level)}>
            {optionsLevel.map(x =>
                <option key={x} value={x}>{labelLevel(x)}</option>)}
        </Form.Control>
    )
}


const optionsVoltage: Voltage[] = ['low', 'medium']

export const VoltageSelector = ({value, onChange}: FieldProps<Voltage>) => {
    return (
        <Form.Control as='select' size='sm' custom value={value} onChange={ev => onChange(ev.target.value as Voltage)}>
            {optionsVoltage.map(x =>
                <option key={x} value={x}>{labelVoltage(x)}</option>)}
        </Form.Control>
    )
}


// generation

interface GenerationPropertiesViewProps {
    generation: Generation
}

const GenerationPropertiesView = observer(({generation}: GenerationPropertiesViewProps) => {
    return (
        <>
            <div className='form-group'>
                <label>Peak Capacity (W)</label>
                <NumberField value={generation.capacity} onChange={x => generation.setCapacity(x)}/>
            </div>
            <div className='form-group'>
                <label>Level of Service</label>
                <LevelSelector value={generation.level} onChange={x => generation.setLevel(x)}/>
            </div>
        </>
    )
})


// node

interface NodePropertiesViewProps {
    node: Node
}

const NodePropertiesView = observer(({node}: NodePropertiesViewProps) => {
    return (
        <>
            <div className='form-group'>
                <label>Customer Type</label>
                <TextField value={node.name} onChange={x => node.setName(x)}/>
            </div>
            <div className='form-group'>
                <label>Peak Power (W)</label>
                <NumberField value={node.demand} onChange={x => node.setDemand(x)}/>
            </div>
            <div className='form-group'>
                <label>Power Factor</label>
                <NumberField value={node.factor} onChange={x => node.setFactor(x)}/>
            </div>
            <div className='form-group'>
                <label>Level of Service</label>
                <LevelSelector value={node.level} onChange={x => node.setLevel(x)}/>
            </div>
            <hr/>
            <div className='form-group'>
            </div>
        </>
    )
})


// line

interface LinePropertiesViewProps {
    line: Line
}

const lengthFormat = new Intl.NumberFormat('en-US', {maximumFractionDigits: 0})

const LinePropertiesView = observer(({line}: LinePropertiesViewProps) => {
    return (
        <>
            <div className='form-group'>
                <label>Conductor</label>
                <ConductorSelector value={line.conductor} onChange={x => line.setConductor(x)}/>
            </div>
            <div className='form-group'>
                <label>Level of Service</label>
                <LevelSelector value={line.level} onChange={x => line.setLevel(x)}/>
            </div>
            <div className='form-group'>
                <label>Voltage</label>
                <VoltageSelector value={line.voltage} onChange={x => line.setVoltage(x)}/>
            </div>
            <hr/>
            <div className='form-group'>
                <p>Length: <strong>{lengthFormat.format(line.length)}</strong> (m)</p>
            </div>
        </>
    )
})

export const ConductorSelector = ({value, onChange, className}: FieldProps<Conductor>) => {
    const i = defaultConductors.indexOf(value)
    return (
        <Form.Control className={className} as='select' size='sm' custom value={i} onChange={ev => onChange(defaultConductors[Number(ev.target.value)])}>
            {defaultConductors.map((x, index) =>
                <option key={x.id} value={index}>{x.name}</option>)}
        </Form.Control>
    )
}
